import * as React from "react";
import "./home.css"
import {Accordion, Container, Dimmer, Icon, Image, Loader, Segment} from "semantic-ui-react";
import {v4 as uuid} from 'uuid';
import {SocialIcon} from "react-social-icons";

const resumeUrl = "https://cdr-personal-website.s3.amazonaws.com/resume.pdf";

export default class Home extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            content: {},
            width: 0,
            height: 0,
            activeIndex: 0,
            loading: true
        };
        window.scrollTo(0, 0);
        this.retrieveContent = this.retrieveContent.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.getExperiencePanels = this.getExperiencePanels.bind(this);
        this.retrieveContent();
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({width: window.innerWidth, height: window.innerHeight});
    }

    retrieveContent() {
        fetch('/api/content', {
            method: "get"
        }).then(response => response.json()).then(response => {
            this.setState({content: response, loading: false})
        });
    }


    handleClick = (e, titleProps) => {
        const {index} = titleProps;
        const {activeIndex} = this.state;
        const newIndex = activeIndex === index ? -1 : index;

        this.setState({activeIndex: newIndex})
    };


    getExperiencePanels() {
        let panels = [];
        this.state.content.experience.forEach(exp => {
            let points = [];
            exp.points.forEach(bp => {
                points.push(<li key={uuid()}><p>{bp}</p></li>)
            });
            panels.push(
                {
                    key: uuid(),
                    title: {
                        content: <span>{exp.company}<span className={'date'}>{exp.date}</span></span>,
                        icon: <Image className={'icon'} src={exp.icon}/>
                    },
                    content: {
                        content: (
                            <>
                                <h5>{exp.title}</h5>
                                <ul>
                                    {points}
                                </ul>

                            </>
                        ),
                    },
                })
        });
        return panels;
    }

    getEducationPanels() {
        let panels = [];
        this.state.content.education.forEach(exp => {
            let points = [];
            exp.points.forEach(bp => {
                points.push(<li key={uuid()}><p>{bp}</p></li>)
            });
            panels.push(
                {
                    key: uuid(),
                    title: {
                        content: <>
                            <span>{exp.name}
                                {this.state.width >= 800 && !!exp.college &&
                                <span className={'subtitle'}>({exp.college})</span>}
                                <span className={'date'}>Graduation - {exp.graduation}</span></span>
                        </>,
                        icon: <Image className={'icon'} src={exp.icon}/>
                    },
                    content: {
                        content: (
                            <>
                                {!!exp.degree &&
                                <h5>{exp.degree}</h5>}
                                <a href={exp.website}><h5 className={'panel-link'}>{exp.website}</h5></a>

                                <ul>
                                    {points}
                                </ul>
                            </>
                        ),
                    },
                })
        });
        return panels;
    }

    getProjectPanels() {
        let panels = [];
        this.state.content.projects.forEach(project => {
            panels.push(
                {
                    key: uuid(),
                    title: {
                        content: <span>{project.name} <span className={'date'}>{project.date}</span></span>
                    },
                    content: {
                        content: (
                            <>
                                <h5 style={{padding: '0px'}}>{project.stack}</h5>
                                <a href={project.website}><h5 style={{padding: '0px'}} className={'panel-link'}>{project.website}</h5></a>
                                <p>{project.description}</p>
                            </>
                        ),
                    },
                })
        });
        return panels;
    }

    getLeadershipPanels() {
        let panels = [];
        this.state.content.leadership.forEach(exp => {
            let points = [];
            exp.points.forEach(bp => {
                points.push(<li key={uuid()}><p>{bp}</p></li>)
            });
            panels.push(
                {
                    key: uuid(),
                    title: {
                        content: <span>{exp.name}<span className={'date'}>{exp.date}</span></span>,
                        icon: <Image className={'icon'} src={exp.icon}/>,
                    },
                    content: {
                        content: (
                            <div>
                                <a href={exp.website}><h5 className={'panel-link'}>{exp.website}</h5></a>
                                <ul>
                                    {points}
                                </ul>
                            </div>
                        ),
                    },
                })
        });
        return panels;
    }

    getSkills() {
        let skills = [];
        this.state.content.skills.forEach(skill => {
            let set = [];
            skill.set.forEach(item => {
                set.push(<li key={uuid()}><p>{item}</p></li>)
            });
            skills.push((
                <div key={uuid()}>
                    <h5 className={'skills-type'}> {skill.type}</h5>
                    <ul className={'skills-list'}>{set}</ul>
                </div>
            ))
        });
        return skills;
    }

    render() {
        return (
            <div className={'home'}>
                <Container className={'main-container'} text>
                    <h1>Hey! I'm Chris Risley</h1>
                    {this.state.loading ?
                        <Segment style={{'border': 'none', 'boxShadow': 'none', 'marginTop': '40px'}}>
                            <Dimmer active inverted>
                                <Loader inverted>Loading</Loader>
                            </Dimmer>
                        </Segment>
                        :
                        <>
                            <p className={'text'}>
                                {this.state.content.biography}
                            </p>
                            <h3>So far I've ...</h3>
                            <Accordion className={'accordion-sections'}>
                                <Accordion.Title
                                    active={this.state.activeIndex === 2}
                                    index={2}
                                    onClick={this.handleClick}
                                >
                                    <Icon name='computer'/>
                                    <a>worked at <Icon name={'dropdown'}/></a>
                                </Accordion.Title>
                                <Accordion.Content active={this.state.activeIndex === 2}>
                                    <Accordion.Accordion panels={this.getExperiencePanels()}/>
                                </Accordion.Content>
                                <Accordion.Title
                                    active={this.state.activeIndex === 1}
                                    index={1}
                                    onClick={this.handleClick}
                                >
                                    <Icon name='book'/>
                                    <a>studied at <Icon name={'dropdown'}/></a>
                                </Accordion.Title>
                                <Accordion.Content active={this.state.activeIndex === 1}>
                                    <Accordion.Accordion panels={this.getEducationPanels()}/>

                                </Accordion.Content>

                                <Accordion.Title
                                    active={this.state.activeIndex === 3}
                                    index={3}
                                    onClick={this.handleClick}
                                >
                                    <Icon name='compass'/>
                                    <a>led others at <Icon name={'dropdown'}/></a>
                                </Accordion.Title>
                                <Accordion.Content active={this.state.activeIndex === 3}>
                                    <Accordion.Accordion panels={this.getLeadershipPanels()}/>
                                </Accordion.Content>

                                <Accordion.Title
                                    active={this.state.activeIndex === 4}
                                    index={4}
                                    onClick={this.handleClick}
                                >
                                    <Icon name='paper plane'/>
                                    <a>built <Icon name={'dropdown'}/></a>
                                </Accordion.Title>
                                <Accordion.Content active={this.state.activeIndex === 4}>
                                    <Accordion.Accordion panels={this.getProjectPanels()}/>
                                </Accordion.Content>


                                <Accordion.Title
                                    active={this.state.activeIndex === 5}
                                    index={5}
                                    onClick={this.handleClick}
                                >
                                    <Icon name='cogs'/>
                                    <a>learned <Icon name={'dropdown'}/></a>
                                </Accordion.Title>
                                <Accordion.Content active={this.state.activeIndex === 5}>
                                    {this.getSkills()}
                                </Accordion.Content>
                                <Accordion.Title
                                    active={this.state.activeIndex === 6}
                                    index={6}
                                    onClick={this.handleClick}
                                >
                                    <Icon name='gamepad'/>
                                    <a>and enjoy<Icon name={'dropdown'}/></a>
                                </Accordion.Title>
                                <Accordion.Content active={this.state.activeIndex === 6}>
                                    <p style={{padding: '10px'}}>{this.state.content.interests}</p>
                                </Accordion.Content>

                            </Accordion>

                            <p className={'text'}>Contact me at <a href={'mailto:risleychris@gmail.com'}><Icon name={'mail'}/>risleychris@gmail.com</a>.
                            </p>
                            <p className={'text'}>Here's my <a href={resumeUrl}>resume</a></p>
                            <p className={'text'}>Social media</p>
                            <div className={'social-buttons'}>
                                <SocialIcon className={'social-icon'}
                                            url="https://www.linkedin.com/in/christopherrisley/"/>
                                <SocialIcon className={'social-icon'}
                                            url="https://github.com/CrizR"/>
                            </div>
                        </>
                    }

                </Container>
            </div>
        )
    }
};

