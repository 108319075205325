import * as React from "react";
import {Button, Loader} from "semantic-ui-react";
import "./admin.css"
import config from "../../auth/auth_config";
import 'react-notifications-component/dist/theme.css'
import ReactNotification from 'react-notifications-component'
import {store} from 'react-notifications-component';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';
import {v4 as uuid} from "uuid";
/**
 * Class used to allow me to edit my personal website's JSON that's stored in AWS.
 */
export default class Admin extends React.Component {

    constructor(props) {
        super(props);
        this.state = {content: {}, loading: true, saving: false};
        this.logout = props.logout;
        window.scrollTo(0, 0);
        this.retrieveContent = this.retrieveContent.bind(this);
        this.saveContent = this.saveContent.bind(this);
        this.retrieveContent();
        this.handleChange = this.handleChange.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

        props.getAccessToken({
            audience: config.audience,
            scope: config.scope,
        }).then((token) => {
            this.accessToken = token;
        });

    }

    /**
     * On component mount watched for window updates
     */
    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    /**
     * On component unmount, remove listener
     */
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    /**
     * Update state with dimensions from listener
     */
    updateWindowDimensions() {
        this.setState({width: window.innerWidth, height: window.innerHeight});
    }

    /**
     * Retrieve content from API and AWS
     */
    retrieveContent() {
        fetch('/api/content', {
            method: "get"
        }).then(response => response.json()).then(response => {
            this.setState({content: response, loading: false})
        });
    }


    /**
     * Save content to AWS
     */
    saveContent() {
        this.setState({saving: true});
        fetch('/api/content', {
            method: "post",
            headers: {
                'Accept': 'application/json',
                "Authorization": 'Bearer ' + this.accessToken,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(this.state.content)
        }).then(response => response.json()).then(response => {
            this.setState({saving: false});
            let notification = {};
            if (response.status === 201) {
                notification = Admin.getNotification("Saved", "success");
            } else {
                notification = Admin.getNotification("Failed", "danger");
            }
            store.addNotification(notification);
            console.log(response)

        });
    }

    /**
     * Helper function to get notification.
     * @param msg to show
     * @param type of message
     * @returns notification object
     */
    static getNotification(msg, type) {
        return {
            message: msg,
            type: type,
            insert: "top",
            showIcon: true,
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 1000,
                onScreen: true,
                showIcon: true,
            }
        };
    }


    /**
     * On content change, update the state with new json
     * @param e
     */
    handleChange(e) {
        console.log(e);
        this.setState({content: e.jsObject})
    }


    /**
     * Render the notifications, particles, and JSON.
     * @returns {*}
     */
    render() {
        return (
            <>
                <ReactNotification className={'notifications'}/>
                <div className={'admin'}>
                    {this.state.loading ?
                        <Loader size={'large'} active inline='centered' className={'loading-component'}/>
                        :
                        <>
                            <h3 className={'title'}>Admin Editor</h3>
                            <div className={'editor'}>
                                <JSONInput
                                    id={uuid()}
                                    placeholder={this.state.content}
                                    locale={locale}
                                    height='600px'
                                    width='100%'
                                    waitAfterKeyPress={2000}
                                    onChange={this.handleChange}
                                />
                            </div>
                            <Button loading={this.state.saving} className={'save'} onClick={this.saveContent} icon>
                                Save
                            </Button>
                        </>
                    }
                </div>
            </>

        )
    }
};

