import React from 'react';
import './App.css';
import 'semantic-ui-css/semantic.min.css'
import history from "./utils/history";
import {Route, Router, Switch} from "react-router-dom";
import PrivateRoute from "./auth/PrivateRoute";
import Home from "./components/home/home";
import Admin from "./components/admin/admin";
import { useAuth0 } from "@auth0/auth0-react";
import {Loader} from "semantic-ui-react";

function App() {
    const {loading} = useAuth0();

    if (loading) {
        return <Loader size={'large'} active inline='centered' className={'loading-component'}/>
            ;
    }

    return (
        <Router history={history}>
            <div className="App">
                <Switch>
                    <Route exact path={"/"} component={Home}/>
                    <Route exact path={"/home"} component={Home}/>
                    <PrivateRoute exact path={"/admin"} component={Admin}/>
                </Switch>
            </div>
        </Router>

    );
}

String.prototype.toProperCase = function () {
    return this.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
};

export default App;
